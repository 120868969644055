import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import Button from '~components/Button'
import SanityLink from '~components/SanityLink'

const ProgramTile = ({ className, program, hoverTilt, index }) => {

	const [hover, setHover] = useState()

	const rotation = () => {
		if(index % 2 == 1) {
			return 'rotate(-2.03deg) translateY(-0px)'
			
		} else{
			return 'rotate(1.11deg)'
		}
	}

	return (
		<Wrap 
			className={className} 
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Tilt
				hoverTilt={hoverTilt}
				tilt={rotation}
				hover={hover}
			>
				<Tile>
					<TileLink link={{
						linkType: 'internal',
						document: {
							slug: program?.slug,
							_type: 'program',
						}
					}}/>
					<Image image={program.headerImage}/>
					<Content>
						<Title>{program.title}</Title>
						<Time>{program.time}</Time>
						<Button color='green'>Learn more</Button>
					</Content>
				</Tile>
			</Tilt>
		</Wrap>
	)
}

const Wrap = styled.div`
`
const Tilt = styled.div`
	transform: ${props => (props.hover && props.hoverTilt) ? props.tilt : 'rotate(0deg)'};
	transition: transform 0.3s;
	transform-origin: center center;
`
const Tile = styled.div`
	position: relative;	
	border-radius: 10px;
	overflow: hidden;
	background-color: var(--white);
`
const TileLink = styled(SanityLink)`
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	top: 0;
	z-index: 2;
`
const Content = styled.div`
	padding: var(--m);
`
const Title = styled.h4`
	margin-bottom: var(--s);
`
const Time = styled.div`
	color: var(--green);
	margin-bottom: var(--m);
`

ProgramTile.propTypes = {
	className: PropTypes.string,
	program: PropTypes.object,
	tilt: PropTypes.string,
	hoverTilt: PropTypes.bool,
	index: PropTypes.number,
}

export default ProgramTile